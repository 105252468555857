* {
  margin: 0;
  padding: 0;
}

.App {
  font-family: 'Pangolin', cursive;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  height: 100vh;
  width: 100%;
}

:root{
  --background:#E8F3DD;
  --text-primary:#002E2E;
  --primary:#002E2E;
  --secondary: #F9A01B;
  --secondary-transparent: #f9a01b85;
  --text-secondary: #E8F3DD;
  --box-shadow:rgba(0, 0, 0, 0.1);

}
