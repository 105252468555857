.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background);
  font-family: "Pangolin", cursive;
  color: var(--text-primary);
  text-align: center;
  padding: 10px;
  img {
    max-width: 200px;
  }
}
